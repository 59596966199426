import {
  BlockCellId,
  CREATE_CELL,
  CellContentsPayload,
  CellId,
  CellType,
  DELETE_CELL,
  FRACTIONAL_INDEX_END,
  FRACTIONAL_INDEX_START,
  HexVersionAtomicOperation,
  MOVE_CELL,
  StaticCellId,
  StoryElementId,
  createStoryElementPayload,
  fractionalIndexMidpoint95,
  uuid,
} from "@hex/common";
import { useCallback, useMemo } from "react";

import { DispatchAOResult } from "../../atomic-operations";
import {
  getLastChildCellInBlock,
  getOrderedChildCells,
  getPrimaryCellId,
} from "../../components/cell/renderers/block/utils.js";
import { useCellContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellContentsStateHooks";
import { useCellGetter } from "../../hex-version-multiplayer/state-hooks/cellStateHooks";
import { useStore } from "../../redux/hooks";
import {
  CellMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { selectSelectedCellIds } from "../../redux/slices/logicViewSlice";
import { useHexVersionAOContext } from "../../util/hexVersionAOContext";
import { useProjectContext } from "../../util/projectContext";

import { useCellOrdersGetter } from "./useCellOrdersGetter";
import { useFirstSelectedCellIdGetter, useSelectCell } from "./useSelectCell";

export interface InsertOptions {
  /** Optional- when passed, used as the value for cellId in the produced CREATE_CELL operation */
  cellIdOverride?: CellId;
  runAfterInsertion?: boolean;
  parentBlockCellId?: BlockCellId;
  parentCellId?: CellId;
  excludeFromHistory?: boolean;
  // use for tracking purposes
  isTemplate?: boolean;
}

export interface InsertCellOpPayload {
  newCellId: CellId;
  operation: CREATE_CELL;
}

export interface UseInsertCellOpResult {
  insertAfterOp: (
    params: CellContentsPayload,
    cellId: CellId | undefined,
    options?: InsertOptions,
  ) => InsertCellOpPayload;
  /**
   * A "smarter" function for automatically inserting a cell at the right place
   * as the result of some global command, like clicking a sidebar button or a command pallete action.
   * Also auto-selects the cell.
   */
  globalInsertOp: (
    params: CellContentsPayload,
    options?: InsertOptions,
  ) => InsertCellOpPayload;
  // if cellId is undefined, insert at start
  insertBeforeOp: (
    params: CellContentsPayload,
    cellId: CellId | undefined,
    options?: InsertOptions,
  ) => InsertCellOpPayload;
  insertAtOp: (
    params: CellContentsPayload,
    insertAt: string,
    options?: InsertOptions,
  ) => InsertCellOpPayload;
  getCellContext: (cellId: CellId | undefined) => {
    firstCell?: CellMP;
    lastCell?: CellMP;
    previousCell?: CellMP;
    followingCell?: CellMP;
    currentCell?: CellMP;
  };
  getFirstCellInBlock: (cell: CellMP) => CellId;
  getLastCellInBlock: (cell: CellMP) => CellId;
}

export function useInsertCellOp(): UseInsertCellOpResult {
  const getCellOrders = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });
  const getCell = useCellGetter({ safe: true });
  const getFirstSelectedCellId = useFirstSelectedCellIdGetter();
  const { hexVersionId } = useProjectContext({ safe: true }) ?? {};
  const store = useStore();
  const getCellContents = useCellContentsGetter({ safe: true });

  const getGlobalInsertTarget = useCallback(() => {
    const firstSelectedCellId = getFirstSelectedCellId();
    if (firstSelectedCellId != null) {
      const selectedCell = getCell(firstSelectedCellId);
      if (selectedCell?.deletedDate == null) {
        return firstSelectedCellId;
      }
    }
  }, [getCell, getFirstSelectedCellId]);

  const getCellContext = useCallback(
    (
      cellId: CellId | undefined,
    ): {
      firstCell?: CellMP;
      lastCell?: CellMP;
      previousCell?: CellMP;
      followingCell?: CellMP;
      currentCell?: CellMP;
    } => {
      const cells = getCellOrders();
      if (!cellId) {
        return {
          firstCell: cells[0],
          lastCell: cells[cells.length - 1],
        };
      }

      // for actions on cells in components, the cellId will be already filtered out, so we look for the parentCell instead
      const parentComponentImportCellId =
        getCell(cellId)?.parentComponentImportCellId;
      const currentCellInComponent = parentComponentImportCellId != null;
      const cellIndex = currentCellInComponent
        ? cells.findIndex(
            (cell) =>
              cell.componentImportCellId === parentComponentImportCellId,
          )
        : cells.findIndex((cell) => cell.id === cellId);

      if (cellIndex > -1) {
        return {
          firstCell: cells[0],
          lastCell: cells[cells.length - 1],
          currentCell: cells[cellIndex],
          previousCell: cells[cellIndex - 1],
          followingCell: cells[cellIndex + 1],
        };
      } else {
        throw new Error(`Unable to find context for cell ${cellId}`);
      }
    },
    [getCell, getCellOrders],
  );

  const getLastCellInBlock = useCallback(
    (currentCell: CellMP) => {
      if (hexVersionId == null) {
        throw new Error("Expected hexVersionId to be defined");
      }
      let blockCellContents;
      if (currentCell?.parentBlockCellId) {
        blockCellContents = hexVersionMPSelectors
          .getCellContentSelectors(hexVersionId)
          .selectByCellContentsId(
            store.getState(),
            currentCell.parentBlockCellId,
          );
      } else if (currentCell.cellType === CellType.BLOCK) {
        blockCellContents = getCellContents(currentCell.id);
      }

      if (blockCellContents?.__typename !== "BlockCell") {
        throw new Error(
          `Expected cell ${currentCell.id} to be a block cell or a child of a block cell`,
        );
      }

      const lastChildId = getLastChildCellInBlock({
        blockCellId: blockCellContents.blockCellId,
        hexVersionId,
        state: store.getState(),
      });

      return lastChildId ?? currentCell.id;
    },
    [getCellContents, hexVersionId, store],
  );

  const getFirstCellInBlock = useCallback(
    (currentCell: CellMP) => {
      if (hexVersionId == null) {
        throw new Error("Expected hexVersionId to be defined");
      }
      if (currentCell?.parentBlockCellId) {
        const blockCellContents = hexVersionMPSelectors
          .getCellContentSelectors(hexVersionId)
          .selectByCellContentsId(
            store.getState(),
            currentCell.parentBlockCellId,
          );
        if (blockCellContents == null) {
          throw new Error("Unable to find block cell contents");
        }
        return blockCellContents.cellId;
      } else if (currentCell.cellType === CellType.BLOCK) {
        return currentCell.id;
      } else {
        throw new Error(
          `Expected cell ${currentCell.id} to be a block cell or a child of a block cell`,
        );
      }
    },
    [hexVersionId, store],
  );

  const insertBeforeOp: UseInsertCellOpResult["insertBeforeOp"] = useCallback(
    (params, cellId, options = {}) => {
      const { currentCell, firstCell, previousCell } = getCellContext(cellId);

      const insertAfterIndex = previousCell?.order;
      const insertBeforeIndex = currentCell?.order ?? firstCell?.order;
      const insertAt = fractionalIndexMidpoint95(
        insertAfterIndex ?? FRACTIONAL_INDEX_START,
        insertBeforeIndex ?? FRACTIONAL_INDEX_END,
      );

      const {
        cellIdOverride: newCellId = uuid() as CellId,
        excludeFromHistory,
        isTemplate,
        parentBlockCellId,
        parentCellId,
        runAfterInsertion,
      } = options;

      return {
        newCellId,
        operation: CREATE_CELL.create({
          cellId: newCellId,
          staticCellId: uuid() as StaticCellId,
          insertAfter: undefined,
          insertBefore: undefined,
          insertAt,
          contents: params,
          storyElement: createStoryElementPayload({
            id: uuid() as StoryElementId,
          }),
          runAfterInsertion,
          componentImportCellId:
            params.type === "COMPONENT_IMPORT" ? params.id ?? null : null,
          blockCellId: params.type === "BLOCK" ? params.id ?? null : null,
          parentBlockCellId,
          parentCellId,
          excludeFromHistory,
          isTemplate,
        }),
      };
    },
    [getCellContext],
  );

  const insertAfterOp: UseInsertCellOpResult["insertAfterOp"] = useCallback(
    (params, cellId, options = {}) => {
      const cellContext = getCellContext(cellId);
      let currentCell = cellContext.currentCell;
      let followingCell = cellContext.followingCell;
      const lastCell = cellContext.lastCell;

      // if we're inserting after a block cell or a cell in a block, we want to insert after the last cell in the block
      if (
        (currentCell?.cellType === CellType.BLOCK ||
          currentCell?.parentBlockCellId != null) &&
        options.parentBlockCellId == null
      ) {
        const newCellContext = getCellContext(getLastCellInBlock(currentCell));
        currentCell = newCellContext.currentCell;
        followingCell = newCellContext.followingCell;
      }

      const insertAfterIndex = currentCell?.order ?? lastCell?.order;
      const insertBeforeIndex = followingCell?.order;
      const insertAt = fractionalIndexMidpoint95(
        insertAfterIndex ?? FRACTIONAL_INDEX_START,
        insertBeforeIndex ?? FRACTIONAL_INDEX_END,
      );

      const {
        cellIdOverride: newCellId = uuid() as CellId,
        excludeFromHistory,
        isTemplate,
        parentBlockCellId,
        parentCellId,
        runAfterInsertion,
      } = options;

      return {
        newCellId,
        operation: CREATE_CELL.create({
          cellId: newCellId,
          staticCellId: uuid() as StaticCellId,
          insertAfter: undefined,
          insertBefore: undefined,
          insertAt,
          contents: params,
          storyElement: createStoryElementPayload({
            id: uuid() as StoryElementId,
          }),
          runAfterInsertion,
          componentImportCellId:
            params.type === "COMPONENT_IMPORT" ? params.id ?? null : null,
          parentBlockCellId,
          parentCellId,
          blockCellId: params.type === "BLOCK" ? params.id ?? null : null,
          excludeFromHistory,
          isTemplate,
        }),
      };
    },
    [getCellContext, getLastCellInBlock],
  );

  const insertAtOp: UseInsertCellOpResult["insertAtOp"] = useCallback(
    (params, insertAt, options = {}) => {
      const {
        cellIdOverride: newCellId = uuid() as CellId,
        excludeFromHistory,
        isTemplate,
        parentBlockCellId,
        parentCellId,
        runAfterInsertion,
      } = options;

      return {
        newCellId,
        operation: CREATE_CELL.create({
          cellId: newCellId,
          staticCellId: uuid() as StaticCellId,
          insertAfter: undefined,
          insertBefore: undefined,
          insertAt,
          contents: params,
          storyElement: createStoryElementPayload({
            id: uuid() as StoryElementId,
          }),
          runAfterInsertion,
          componentImportCellId:
            params.type === "COMPONENT_IMPORT" ? params.id ?? null : null,
          parentBlockCellId,
          parentCellId,
          blockCellId: params.type === "BLOCK" ? params.id ?? null : null,
          excludeFromHistory,
          isTemplate,
        }),
      };
    },
    [],
  );

  const globalInsertOp: UseInsertCellOpResult["globalInsertOp"] = useCallback(
    (params, options) => {
      const targetCellId = getGlobalInsertTarget();
      const { newCellId, operation } = insertAfterOp(
        params,
        targetCellId,
        options,
      );
      return { newCellId, operation };
    },
    [getGlobalInsertTarget, insertAfterOp],
  );

  return useMemo(
    () => ({
      insertAfterOp,
      insertBeforeOp,
      insertAtOp,
      globalInsertOp,
      getCellContext,
      getFirstCellInBlock,
      getLastCellInBlock,
    }),
    [
      insertAfterOp,
      insertBeforeOp,
      insertAtOp,
      globalInsertOp,
      getCellContext,
      getFirstCellInBlock,
      getLastCellInBlock,
    ],
  );
}

export interface UseInsertCellResult {
  // if cellId is undefined, insert at end
  moveAfter: (cellIdToMove: CellId, cellId: CellId | undefined) => void;
  insertAfter: (
    params: CellContentsPayload,
    cellId: CellId | undefined,
    options?: InsertOptions,
  ) => { cellId: CellId; dispatchResult: DispatchAOResult };
  /**
   * A "smarter" function for automatically inserting a cell at the right place
   * as the result of some global command, like clicking a sidebar button or a command pallete action.
   * Also auto-selects the cell.
   */
  globalInsert: (params: CellContentsPayload, options?: InsertOptions) => void;
  // if cellId is undefined, insert at start
  moveBefore: (cellIdToMove: CellId, cellId: CellId | undefined) => void;
  insertBefore: (
    params: CellContentsPayload,
    cellId: CellId | undefined,
    options?: InsertOptions,
  ) => { cellId: CellId; dispatchResult: DispatchAOResult };
  deleteCells: (cellsToDelete: CellId[]) => void;
  moveCellsUp: (cellIdsToMove: CellId[]) => void;
  moveCellsDown: (cellIdsToMove: CellId[]) => void;
}

export function useInsertCell(): UseInsertCellResult {
  const {
    getCellContext,
    getFirstCellInBlock,
    getLastCellInBlock,
    globalInsertOp,
    insertAfterOp,
    insertBeforeOp,
  } = useInsertCellOp();

  const store = useStore();
  const { dispatchAO } = useHexVersionAOContext();
  const getCellOrders = useCellOrdersGetter();
  const { scrollToCell, selectCells } = useSelectCell();
  const getCell = useCellGetter({ safe: true });
  const { hexVersionId } = useProjectContext({ safe: true }) ?? {};

  const getMoveBlockCellActions = useCallback(
    ({
      blockCellId,
      insertAt,
      insertBeforeIndex,
    }: {
      blockCellId: BlockCellId;
      insertAt: string;
      insertBeforeIndex?: string;
    }): HexVersionAtomicOperation[] => {
      if (hexVersionId == null) {
        throw new Error("Expected hexVersionId to be defined");
      }
      const parentBlockCellContents = hexVersionMPSelectors
        .getCellContentSelectors(hexVersionId)
        .selectByCellContentsId(store.getState(), blockCellId);

      if (
        parentBlockCellContents?.__typename !== "BlockCell" ||
        parentBlockCellContents?.blockConfig == null
      ) {
        throw new Error(`Expected cell ${blockCellId} to be a block cell`);
      }

      const cellIdsToMove = getOrderedChildCells({
        blockCellId: parentBlockCellContents.blockCellId,
        hexVersionId,
        parentCellId: parentBlockCellContents.cellId,
        state: store.getState(),
      });

      let currentInsertAt = insertAt;
      const actions: HexVersionAtomicOperation[] = [];

      const primaryCellId = getPrimaryCellId(
        parentBlockCellContents.blockConfig,
      );

      cellIdsToMove.forEach((cellIdToMoveInBlock) => {
        actions.push(
          MOVE_CELL.create({
            cellId: cellIdToMoveInBlock,
            parentCellId:
              cellIdToMoveInBlock === parentBlockCellContents.cellId
                ? undefined
                : parentBlockCellContents.cellId,
            insertAfter: undefined,
            insertAt: currentInsertAt,
            insertBefore: undefined,
            // If we have a primary cell, exclude the rest of cells from history
            // if not just add all the cells to the history
            excludeFromHistory: primaryCellId
              ? cellIdToMoveInBlock !== primaryCellId
              : false,
          }),
        );
        currentInsertAt = fractionalIndexMidpoint95(
          currentInsertAt,
          insertBeforeIndex ?? FRACTIONAL_INDEX_END,
        );
      });

      return actions;
    },
    [hexVersionId, store],
  );

  const insertAfter: UseInsertCellResult["insertBefore"] = useCallback(
    (params, cellId, options = {}) => {
      const { newCellId, operation } = insertAfterOp(params, cellId, options);
      const dispatchResult = dispatchAO(operation);
      return { cellId: newCellId, dispatchResult };
    },
    [dispatchAO, insertAfterOp],
  );

  const insertBefore: UseInsertCellResult["insertAfter"] = useCallback(
    (params, cellId, options = {}) => {
      const { newCellId, operation } = insertBeforeOp(params, cellId, options);
      const dispatchResult = dispatchAO(operation);
      return { cellId: newCellId, dispatchResult };
    },
    [dispatchAO, insertBeforeOp],
  );

  const globalInsert: UseInsertCellResult["globalInsert"] = useCallback(
    (params, options) => {
      const { newCellId, operation } = globalInsertOp(params, options);
      const dispatchResult = dispatchAO(operation);
      // TODO(VELO-5389): fix this scrolling behavior
      selectCells(newCellId);
      return { cellId: newCellId, dispatchResult };
    },
    [dispatchAO, globalInsertOp, selectCells],
  );

  const moveBefore: UseInsertCellResult["moveBefore"] = useCallback(
    (cellIdToMove, cellId) => {
      const cellContext = getCellContext(cellId);
      let currentCell = cellContext.currentCell;
      let previousCell = cellContext.previousCell;
      const firstCell = cellContext.firstCell;

      const cellToMove = getCell(cellIdToMove);
      // if we're moving before a cell in a block, move to before the parent block cell
      if (
        currentCell?.cellType === CellType.BLOCK ||
        currentCell?.parentBlockCellId != null
      ) {
        const newCellContext = getCellContext(getFirstCellInBlock(currentCell));
        currentCell = newCellContext.currentCell;
        previousCell = newCellContext.previousCell;
      }

      const insertAfterIndex = previousCell?.order;
      const insertBeforeIndex = currentCell?.order ?? firstCell?.order;
      const insertAt = fractionalIndexMidpoint95(
        insertAfterIndex ?? FRACTIONAL_INDEX_START,
        insertBeforeIndex ?? FRACTIONAL_INDEX_END,
      );

      // if cell to move is in a block, we need to move all cells in the block in order
      if (
        cellToMove?.parentBlockCellId != null ||
        cellToMove?.cellType === CellType.BLOCK
      ) {
        const blockCellId =
          cellToMove.parentBlockCellId ?? cellToMove.blockCellId;

        if (blockCellId == null) {
          throw new Error(
            `Expected blockCellId to be defined for cell ${cellToMove.id}`,
          );
        }

        const actions = getMoveBlockCellActions({
          blockCellId,
          insertAt,
          insertBeforeIndex,
        });
        return dispatchAO(actions);
      } else {
        return dispatchAO(
          MOVE_CELL.create({
            cellId: cellIdToMove,
            insertAfter: undefined,
            insertAt,
            insertBefore: undefined,
            parentCellId: undefined,
          }),
        );
      }
    },
    [
      dispatchAO,
      getCell,
      getCellContext,
      getFirstCellInBlock,
      getMoveBlockCellActions,
    ],
  );

  const moveAfter: UseInsertCellResult["moveAfter"] = useCallback(
    (cellIdToMove, cellId) => {
      const cellContext = getCellContext(cellId);
      let currentCell = cellContext.currentCell;
      let followingCell = cellContext.followingCell;
      const lastCell = cellContext.lastCell;

      const cellToMove = getCell(cellIdToMove);
      // if we're moving after a block cell or a cell in a block, we want to insert after the last cell in the block
      if (
        currentCell?.cellType === CellType.BLOCK ||
        currentCell?.parentBlockCellId != null
      ) {
        const newCellContext = getCellContext(getLastCellInBlock(currentCell));
        currentCell = newCellContext.currentCell;
        followingCell = newCellContext.followingCell;
      }

      const insertAfterIndex = currentCell?.order ?? lastCell?.order;
      const insertBeforeIndex = followingCell?.order;
      const insertAt = fractionalIndexMidpoint95(
        insertAfterIndex ?? FRACTIONAL_INDEX_START,
        insertBeforeIndex ?? FRACTIONAL_INDEX_END,
      );

      // if cell to move is in a block, we need to move all cells in the block in order
      if (
        cellToMove?.parentBlockCellId != null ||
        cellToMove?.cellType === CellType.BLOCK
      ) {
        const blockCellId =
          cellToMove.parentBlockCellId ?? cellToMove.blockCellId;

        if (blockCellId == null) {
          throw new Error(
            `Expected blockCellId to be defined for cell ${cellToMove.id}`,
          );
        }

        const actions = getMoveBlockCellActions({
          blockCellId,
          insertAt,
          insertBeforeIndex,
        });
        return dispatchAO(actions);
      } else {
        return dispatchAO(
          MOVE_CELL.create({
            cellId: cellIdToMove,
            insertAfter: undefined,
            insertAt,
            insertBefore: undefined,
            parentCellId: undefined,
          }),
        );
      }
    },
    [
      dispatchAO,
      getCell,
      getCellContext,
      getLastCellInBlock,
      getMoveBlockCellActions,
    ],
  );

  const deleteCells: UseInsertCellResult["deleteCells"] = useCallback(
    (cellsToDelete) => {
      const operations = cellsToDelete.map((cellId) =>
        DELETE_CELL.create({ cellId }),
      );

      const hasSelection = selectSelectedCellIds(store.getState()).size > 0;
      if (hasSelection) {
        // Select the next cell before deleting, otherwise we won't be able to
        const lastCellToDelete = cellsToDelete[cellsToDelete.length - 1];
        let followingCell = getCellContext(lastCellToDelete).followingCell;
        const firstCellToDelete = cellsToDelete[0];
        let previousCell = getCellContext(firstCellToDelete).previousCell;

        let selected = false;
        if (followingCell != null) {
          // keep going to the end until we find a non component cell
          while (followingCell != null) {
            if (
              followingCell.parentComponentImportCellId == null &&
              followingCell.parentBlockCellId == null
            ) {
              selectCells(followingCell.id);
              selected = true;
              break;
            }
            followingCell = getCellContext(followingCell.id).followingCell;
          }
        }
        if (!selected && previousCell != null) {
          // keep going to the start until we find a non component cell
          while (previousCell != null) {
            if (
              previousCell.parentComponentImportCellId == null &&
              previousCell.parentBlockCellId == null
            ) {
              selectCells(previousCell.id);
              selected = true;
              break;
            }
            previousCell = getCellContext(previousCell.id).previousCell;
          }
        }
        if (!selected) {
          selectCells(undefined);
        }
      }
      return dispatchAO(operations);
    },
    [dispatchAO, getCellContext, selectCells, store],
  );

  const moveCellsUp: UseInsertCellResult["moveCellsUp"] = useCallback(
    (cellIdsToMove) => {
      const { firstCell } = getCellContext(undefined);
      const flattenedCells = getCellOrders();
      let firstCellIdToMove = cellIdsToMove[0];

      const firstCellToMove = getCell(firstCellIdToMove);
      // if the first cell to move is a block or in a block
      // update it to be the first cell in that block
      if (
        firstCellToMove?.cellType === CellType.BLOCK ||
        firstCellToMove?.parentBlockCellId != null
      ) {
        firstCellIdToMove = getFirstCellInBlock(firstCellToMove);
      }

      if (firstCellIdToMove === firstCell?.id) {
        return;
      }
      const firstCellToMoveIndex = flattenedCells.findIndex(
        (c) => c.id === firstCellIdToMove,
      );
      // If moving multiple cells up, move the previous cell down instead
      if (cellIdsToMove.length > 1) {
        const previousCellIndex = firstCellToMoveIndex - 1;
        const previousCell = flattenedCells[previousCellIndex];

        const cellIdToMoveAfter = cellIdsToMove[cellIdsToMove.length - 1];
        moveAfter(previousCell.id, cellIdToMoveAfter);
        scrollToCell(firstCellIdToMove, { scrollBehavior: "smooth" });
      } else {
        if (firstCellToMoveIndex < 0) {
          throw new Error("Invalid cellIdToMove");
        }
        const previousCell = flattenedCells[firstCellToMoveIndex - 1];
        moveBefore(firstCellIdToMove, previousCell?.id);
        scrollToCell(firstCellIdToMove, { scrollBehavior: "smooth" });
      }
    },
    [
      getCell,
      getCellContext,
      getCellOrders,
      getFirstCellInBlock,
      moveAfter,
      moveBefore,
      scrollToCell,
    ],
  );

  const moveCellsDown: UseInsertCellResult["moveCellsDown"] = useCallback(
    (cellIdsToMove) => {
      const { lastCell } = getCellContext(undefined);
      const flattenedCells = getCellOrders();
      let lastCellIdToMove = cellIdsToMove[cellIdsToMove.length - 1];
      const lastCellToMove = getCell(lastCellIdToMove);
      // if the last cell to move is a block or in a block
      // update it to be the last cell in that block
      if (
        lastCellToMove?.cellType === CellType.BLOCK ||
        lastCellToMove?.parentBlockCellId != null
      ) {
        lastCellIdToMove = getLastCellInBlock(lastCellToMove);
      }

      if (lastCellIdToMove === lastCell?.id) {
        return;
      }
      const lastCellToMoveIndex = flattenedCells.findIndex(
        (c) => c.id === lastCellIdToMove,
      );
      // If moving multiple cells down, move the next cell up instead
      if (cellIdsToMove.length > 1) {
        const nextCellIndex = lastCellToMoveIndex + 1;
        const nextCell = flattenedCells[nextCellIndex];

        let firstCellIdToMove = cellIdsToMove[0];
        const firstCellToMove = getCell(firstCellIdToMove);
        if (
          firstCellToMove?.cellType === CellType.BLOCK ||
          firstCellToMove?.parentBlockCellId != null
        ) {
          firstCellIdToMove = getFirstCellInBlock(firstCellToMove);
        }

        moveBefore(nextCell.id, firstCellIdToMove);
        scrollToCell(firstCellIdToMove, { scrollBehavior: "smooth" });
      } else {
        if (lastCellToMoveIndex < 0) {
          throw new Error("Invalid cellIdToMove");
        }
        const nextCell = flattenedCells[lastCellToMoveIndex + 1];
        moveAfter(lastCellIdToMove, nextCell?.id);
        scrollToCell(lastCellIdToMove, { scrollBehavior: "smooth" });
      }
    },
    [
      getCell,
      getCellContext,
      getCellOrders,
      getFirstCellInBlock,
      getLastCellInBlock,
      moveAfter,
      moveBefore,
      scrollToCell,
    ],
  );

  return useMemo(
    () => ({
      globalInsert,
      insertBefore,
      insertAfter,
      moveBefore,
      moveAfter,
      moveCellsDown,
      moveCellsUp,
      deleteCells,
    }),
    [
      deleteCells,
      globalInsert,
      insertAfter,
      insertBefore,
      moveAfter,
      moveBefore,
      moveCellsDown,
      moveCellsUp,
    ],
  );
}
