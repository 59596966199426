import {
  AirlockBlockConfig,
  CellId,
  SQLCellBlockConfig,
  StaticCellId,
  guardNever,
  notEmpty,
  stableEmptyObject,
} from "@hex/common";
import { useCallback, useMemo } from "react";
import { ScrollBehavior } from "scroll-into-view-if-needed/typings/types.js";

import {
  useAirlockBlockCellIds,
  useAirlockCellIds,
  useBlockCellContentsGetter,
} from "../../hex-version-multiplayer/state-hooks/blockCellHooks.js";
import { useCellsSelector } from "../../hex-version-multiplayer/state-hooks/cellsStateHooks.js";
import { useDispatch, useSelector, useStore } from "../../redux/hooks";
import {
  CellContentsMP,
  CellMP,
  hexVersionMPActions,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import {
  addSelectedCellIds,
  closeDraftAirlock,
  removeSelectedCellIds,
  selectSelectedCellIds,
  setMostRecentlySelectedCellId,
  setSelectedCellIds,
} from "../../redux/slices/logicViewSlice";
import { evtModKey } from "../../util/Keys";
import { useProjectContext } from "../../util/projectContext";

import { useCellOrdersGetter } from "./useCellOrdersGetter";
import { CellScrollAPIOptions, useScrollToCell } from "./useScrollToCell.js";

export function useSelectedCellIds(): Set<CellId> {
  return useSelector(selectSelectedCellIds);
}

export function useGetSelectedCellIds(): () => Set<CellId> {
  const store = useStore();
  return useCallback(() => selectSelectedCellIds(store.getState()), [store]);
}

export function useIsCellSelected(cellId: CellId | null): boolean {
  return useSelector((state) => {
    if (cellId == null) {
      return false;
    } else {
      return selectSelectedCellIds(state).has(cellId);
    }
  });
}

export function useIsCellMultiselected(cellId: CellId): boolean {
  return useSelector((state) => {
    const selectedCellIds = selectSelectedCellIds(state);
    return selectedCellIds.has(cellId) && selectedCellIds.size > 1;
  });
}

export function useNumberOfSelectedCells(): number {
  return Object.keys(useSelectedCellsContents()).length;
}

export function useSortedSelectedCellsGetter(): () => readonly CellMP[] {
  const getCellOrders = useCellOrdersGetter();
  const getSelectedCellIds = useGetSelectedCellIds();
  const getBlockConfig = useBlockCellContentsGetter({
    selector: (cell: CellContentsMP) => {
      return cell.__typename === "BlockCell" ? cell.blockConfig : null;
    },
  });

  return useCallback(() => {
    const sortedCells = getCellOrders();
    const selectedCellIds = getSelectedCellIds();
    const sortedSelectedCells = sortedCells.filter(({ id }) =>
      selectedCellIds.has(id),
    );

    const selectedCellsWithBlock = new Set<CellMP>();
    for (const cell of sortedSelectedCells) {
      if (cell.parentBlockCellId != null) {
        const blockConfig = getBlockConfig(cell.parentBlockCellId);
        if (blockConfig == null) {
          // default don't do anything if blockConfig is null because
          // practically this should never happen.
        } else if (SQLCellBlockConfig.guard(blockConfig)) {
          // if the selected cell is in a sql chart block, add the parent block instead
          const parent = sortedCells.find(
            (c) => c.blockCellId === cell.parentBlockCellId,
          );
          if (parent != null) {
            selectedCellsWithBlock.add(parent);
          }
        } else if (AirlockBlockConfig.guard(blockConfig)) {
          // treat airlock child cells normally normally
          selectedCellsWithBlock.add(cell);
        } else {
          guardNever(blockConfig, blockConfig);
        }
      } else {
        selectedCellsWithBlock.add(cell);
      }
    }

    const selectedCellsReturn = Array.from(selectedCellsWithBlock);
    return selectedCellsReturn;
  }, [getBlockConfig, getCellOrders, getSelectedCellIds]);
}

export function useFirstSelectedCellIdGetter(): () => CellId | null {
  const getSortedSelectedCells = useSortedSelectedCellsGetter();

  return useCallback(() => {
    const sortedSelectedCells = getSortedSelectedCells();
    return sortedSelectedCells[0]?.id ?? null;
  }, [getSortedSelectedCells]);
}

export function useLastSelectedCellIdGetter(): () => CellId | null {
  const getSortedSelectedCells = useSortedSelectedCellsGetter();

  return useCallback(() => {
    const sortedSelectedCells = getSortedSelectedCells();
    return sortedSelectedCells[sortedSelectedCells.length - 1]?.id ?? null;
  }, [getSortedSelectedCells]);
}

export function useSelectedCellsContents(): Record<CellId, CellContentsMP> {
  const { hexVersionId } = useProjectContext();

  const cellIds = useSelectedCellIds();
  const cellContents = useSelector((state) => {
    return (
      hexVersionMPSelectors
        .getCellContentSelectors(hexVersionId)
        .selectEntities(state) ?? stableEmptyObject<string, CellContentsMP>()
    );
  });

  return useMemo(() => {
    const result: Record<CellId, CellContentsMP> = {};
    for (const cellId of cellIds) {
      const cellContent = cellContents[cellId];
      if (cellContent != null) {
        result[cellId] = cellContent;
      }
    }
    return result;
  }, [cellContents, cellIds]);
}

interface SelectCellOptions {
  /**
   * Whether or not to replace the existing selection state or just
   * add to it.
   *
   * @default true
   */
  replace?: boolean;
  /**
   * Which part of the new cell to scroll to.
   * Defaults to "source".
   * If multiple are selected, this scrolls to the
   * specified part of the first cell.
   *
   * @default "source"
   */
  scrollTarget?:
    | "source"
    | "output"
    | { type: "lineNumber"; lineNumber: number }
    | "none";

  scrollBehavior?: ScrollBehavior;
  waitForSizeChangesBeforeScrolling?: boolean;
  /**
   * This gets passed through to the closeDraftAirlock action. If true, then
   * this will disable the closing animation for the action bar.
   */
  disableCloseAnimation?: boolean;
}

interface HandleFollowingBlockCellArgs {
  cells: readonly CellMP[];
  nextCell: CellMP | undefined;
  currentIndex: number;
  nextIndex: number;
}

export interface HandlePreviousBlockCell {
  cells: readonly CellMP[];
  prevCell: CellMP | undefined;
  currentIndex: number;
  prevIndex: number;
}

export interface UseSelectCellResult {
  selectCells: (
    cellIds?: CellId | CellId[],
    options?: SelectCellOptions,
  ) => void;
  deselectCells: (cellIds: CellId | CellId[]) => void;
  selectCellsByStaticId: (
    staticCellIds?: StaticCellId | StaticCellId[],
    options?: SelectCellOptions,
  ) => void;
  selectCellRange: (
    startingCellId: CellId,
    endingCellId: CellId,
    options?: SelectCellOptions,
  ) => void;
  selectFollowingCell: (cellId: CellId, options?: SelectCellOptions) => void;
  selectPreviousCell: (cellId: CellId, options?: SelectCellOptions) => void;
  addNextCellToSelectedRange: () => void;
  addPreviousCellToSelectedRange: () => void;
  scrollToCell: (cellId: CellId, options: CellScrollAPIOptions) => void;
}

// eslint-disable-next-line max-lines-per-function
export function useSelectCell(): UseSelectCellResult {
  const { hexVersionId } = useProjectContext();
  const store = useStore();
  const dispatch = useDispatch();
  const getCellOrders = useCellOrdersGetter();
  const getSelectedCellIds = useGetSelectedCellIds();
  const getBlockConfig = useBlockCellContentsGetter({
    selector: (cell: CellContentsMP) => {
      return cell.__typename === "BlockCell" ? cell.blockConfig : null;
    },
  });

  const scrollToCell = useScrollToCell();

  const selectCells: UseSelectCellResult["selectCells"] = useCallback(
    (
      cellIds_ = [],
      {
        disableCloseAnimation = false,
        replace = true,
        scrollBehavior = "smooth",
        scrollTarget = "source",
        waitForSizeChangesBeforeScrolling = false,
      } = {},
    ) => {
      const cellIds = Array.isArray(cellIds_) ? cellIds_ : [cellIds_];
      if (cellIds.length === 0) {
        dispatch(
          hexVersionMPActions.setActiveMagicCell({
            hexVersionId,
            data: null,
          }),
        );
      }
      // on single cell selection we should close draft airlocks, and set magic
      // to be in edit mode for a single cell. This does not set the prompt
      // bar to be open though which depends on if its already open
      if (cellIds.length === 1) {
        dispatch(closeDraftAirlock({ disableCloseAnimation }));
        dispatch(
          hexVersionMPActions.setActiveMagicCell({
            hexVersionId,
            data: cellIds[0],
          }),
        );
        dispatch(
          hexVersionMPActions.setMagicEditMode({
            hexVersionId,
            data: true,
          }),
        );
      }

      if (replace) {
        dispatch(setSelectedCellIds(cellIds));
      } else {
        dispatch(addSelectedCellIds(cellIds));
      }

      if (scrollTarget !== "none" && cellIds.length === 1) {
        scrollToCell(cellIds[0], {
          scrollTarget,
          scrollBehavior,
          waitForSizeChanges: waitForSizeChangesBeforeScrolling,
        });
      }
    },
    [dispatch, hexVersionId, scrollToCell],
  );

  const deselectCells: UseSelectCellResult["deselectCells"] = useCallback(
    (cellIds) => {
      dispatch(
        removeSelectedCellIds(Array.isArray(cellIds) ? cellIds : [cellIds]),
      );
    },
    [dispatch],
  );

  const selectCellsByStaticId: UseSelectCellResult["selectCellsByStaticId"] =
    useCallback(
      (staticCellIds_ = [], options) => {
        const staticCellIds = Array.isArray(staticCellIds_)
          ? staticCellIds_
          : [staticCellIds_];

        const cellIds = staticCellIds
          .map(
            (staticCellId) =>
              store.getState().hexVersionMP[hexVersionId]?.staticCellIdToCellId[
                staticCellId
              ],
          )
          .filter(notEmpty);

        selectCells(cellIds, options);
      },
      [hexVersionId, selectCells, store],
    );

  const selectCellRange: UseSelectCellResult["selectCellRange"] = useCallback(
    (startingCellId, endingCellId, options) => {
      const cells = getCellOrders();
      const startingIndex = cells.findIndex((c) => c.id === startingCellId);
      const endingIndex = cells.findIndex((c) => c.id === endingCellId);

      const startingSliceIndex = Math.min(startingIndex, endingIndex);
      const endingSliceIndex = Math.max(startingIndex, endingIndex);

      const cellsInRange = cells.slice(
        startingSliceIndex,
        endingSliceIndex + 1,
      );

      const shouldReverse = startingIndex > endingIndex;

      if (shouldReverse) {
        cellsInRange.reverse();
      }

      return selectCells(
        cellsInRange.map((cell) => cell.id),
        options,
      );
    },
    [getCellOrders, selectCells],
  );

  /**
   * Handles following cell selection for SQL chart block cells.
   */
  function getFollowingSqlChartCell({
    cells,
    currentIndex,
    nextCell,
    nextIndex,
  }: HandleFollowingBlockCellArgs): CellMP | undefined {
    if (!nextCell) {
      return nextCell;
    }
    while (
      nextCell.parentBlockCellId != null ||
      (nextCell.blockCellId != null &&
        nextCell.blockCellId === cells[currentIndex].parentBlockCellId)
    ) {
      nextIndex = nextIndex + 1;
      if (nextIndex > cells.length - 1) {
        return;
      }
      nextCell = cells[nextIndex];
    }
    return nextCell;
  }

  const maybeHandleSelectFollowingBlockCell = useCallback(
    ({
      cells,
      currentIndex,
      nextCell,
      nextIndex,
    }: HandleFollowingBlockCellArgs): CellMP | undefined => {
      if (!nextCell || (!nextCell.parentBlockCellId && !nextCell.blockCellId)) {
        return nextCell;
      }
      if (nextCell.parentBlockCellId) {
        const blockConfig = getBlockConfig(nextCell.parentBlockCellId);

        if (blockConfig == null) {
          // default don't do anything if blockConfig is null because
          // practically this should never happen.
        } else if (SQLCellBlockConfig.guard(blockConfig)) {
          nextCell = getFollowingSqlChartCell({
            cells,
            currentIndex,
            nextCell,
            nextIndex,
          });
        } else if (AirlockBlockConfig.guard(blockConfig)) {
          // do nothing
        } else {
          guardNever(blockConfig, blockConfig);
        }
      }
      // handles parent block cells
      else if (nextCell.blockCellId) {
        const blockConfig = getBlockConfig(nextCell.blockCellId);

        if (blockConfig == null) {
          // default don't do anything if blockConfig is null because
          // practically this should never happen.
        } else if (SQLCellBlockConfig.guard(blockConfig)) {
          nextCell = getFollowingSqlChartCell({
            cells,
            currentIndex,
            nextCell,
            nextIndex,
          });
        } else if (AirlockBlockConfig.guard(blockConfig)) {
          // airlock block
          nextIndex = nextIndex + 1;
          if (nextIndex > cells.length - 1) {
            return;
          }
          nextCell = cells[nextIndex];
        } else {
          guardNever(blockConfig, blockConfig);
        }
      }
      return nextCell;
    },
    [getBlockConfig],
  );

  const selectFollowingCell: UseSelectCellResult["selectFollowingCell"] =
    useCallback(
      (cellId, options) => {
        const cells = getCellOrders();

        const cellsWithoutChildCells = cells.filter(
          (c) => c.parentBlockCellId == null,
        );
        // We normally want to respect cell orders but this is a case where
        // we want to manually override and force the ordering to be
        // parent, then all its corresponding children.
        const forceOrderCells: CellMP[] = [];
        cellsWithoutChildCells.forEach((cell) => {
          forceOrderCells.push(cell);
          if (cell?.blockCellId != null) {
            const childCells = cells.filter(
              (c) => c.parentBlockCellId === cell.blockCellId,
            );
            forceOrderCells.push(...childCells);
          }
        });

        if (forceOrderCells.length === 0 || cellId == null) {
          return selectCells(undefined, options);
        }
        const currentIndex = forceOrderCells.findIndex((c) => c.id === cellId);
        const nextIndex =
          currentIndex === -1
            ? 0
            : Math.min(forceOrderCells.length - 1, currentIndex + 1);

        let nextCell: CellMP | undefined = forceOrderCells[nextIndex];

        nextCell = maybeHandleSelectFollowingBlockCell({
          cells: forceOrderCells,
          currentIndex,
          nextCell,
          nextIndex,
        });

        if (!nextCell) {
          return;
        }
        return selectCells(nextCell.id, options);
      },
      [getCellOrders, maybeHandleSelectFollowingBlockCell, selectCells],
    );

  /**
   * Handles previous selection for SQL chart block cells.
   */
  function getPreviousSqlChartCell({
    cells,
    currentIndex,
    prevCell,
    prevIndex,
  }: HandlePreviousBlockCell): CellMP | undefined {
    if (!prevCell) {
      return prevCell;
    }
    while (
      prevCell.parentBlockCellId != null ||
      (prevCell.blockCellId != null &&
        prevCell.blockCellId === cells[currentIndex].parentBlockCellId)
    ) {
      prevIndex = prevIndex - 1;
      if (prevIndex < 0) {
        return;
      }
      prevCell = cells[prevIndex];
    }
    return prevCell;
  }

  const maybeHandleSelectPreviousBlockCell = useCallback(
    ({
      cells,
      currentIndex,
      prevCell,
      prevIndex,
    }: HandlePreviousBlockCell): CellMP | undefined => {
      if (!prevCell || (!prevCell.parentBlockCellId && !prevCell.blockCellId)) {
        return prevCell;
      }
      // Handles children of block cells
      if (prevCell.parentBlockCellId != null) {
        const blockConfig = getBlockConfig(prevCell.parentBlockCellId);

        if (blockConfig == null) {
          // default don't do anything if blockConfig is null because
          // practically this should never happen.
        } else if (SQLCellBlockConfig.guard(blockConfig)) {
          prevCell = getPreviousSqlChartCell({
            cells,
            currentIndex,
            prevCell,
            prevIndex,
          });
        } else if (AirlockBlockConfig.guard(blockConfig)) {
          // do nothing
        } else {
          guardNever(blockConfig, blockConfig);
        }
      }
      // handles parent block cells
      else if (prevCell.blockCellId != null) {
        const blockConfig = getBlockConfig(prevCell.blockCellId);

        if (blockConfig == null) {
          // default don't do anything if blockConfig is null because
          // practically this should never happen.
        } else if (SQLCellBlockConfig.guard(blockConfig)) {
          prevCell = getPreviousSqlChartCell({
            cells,
            currentIndex,
            prevCell,
            prevIndex,
          });
        } else if (AirlockBlockConfig.guard(blockConfig)) {
          // airlock block
          prevIndex = prevIndex - 1;
          if (prevIndex < 0) {
            return;
          }
          prevCell = cells[prevIndex];
        } else {
          guardNever(blockConfig, blockConfig);
        }
      }
      return prevCell;
    },
    [getBlockConfig],
  );

  const selectPreviousCell: UseSelectCellResult["selectPreviousCell"] =
    useCallback(
      (cellId, options) => {
        const cells = getCellOrders();
        const cellsWithoutChildCells = cells.filter(
          (c) => c.parentBlockCellId == null,
        );
        // We normally want to respect cell orders but this is a case where
        // we want to manually override and force the ordering to be
        // parent, then all its corresponding children.
        const forceOrderCells: CellMP[] = [];
        cellsWithoutChildCells.forEach((cell) => {
          forceOrderCells.push(cell);
          if (cell?.blockCellId != null) {
            const childCells = cells.filter(
              (c) => c.parentBlockCellId === cell.blockCellId,
            );
            forceOrderCells.push(...childCells);
          }
        });

        if (forceOrderCells.length === 0 || cellId == null) {
          return selectCells(undefined, options);
        }

        const currentIndex = forceOrderCells.findIndex((c) => c.id === cellId);
        const prevIndex =
          currentIndex === -1 ? 0 : Math.max(0, currentIndex - 1);

        let prevCell: CellMP | undefined = forceOrderCells[prevIndex];

        prevCell = maybeHandleSelectPreviousBlockCell({
          cells: forceOrderCells,
          currentIndex,
          prevCell,
          prevIndex,
        });

        if (!prevCell) {
          return;
        }
        return selectCells(prevCell.id, options);
      },
      [getCellOrders, maybeHandleSelectPreviousBlockCell, selectCells],
    );

  const addNextCellToSelectedRange: UseSelectCellResult["addNextCellToSelectedRange"] =
    useCallback(() => {
      const lastSelectedCellId =
        store.getState().logicView.mostRecentlySelectedCellId;
      if (lastSelectedCellId == null) {
        return;
      }
      const selectedCellIds = getSelectedCellIds();
      const cells = getCellOrders();
      const currentIndex = cells.findIndex((c) => c.id === lastSelectedCellId);
      const nextIndex = currentIndex === -1 ? 0 : currentIndex + 1;

      if (nextIndex > cells.length - 1) {
        return;
      }

      let nextCell: CellMP | undefined = cells[nextIndex];
      nextCell = maybeHandleSelectFollowingBlockCell({
        cells,
        currentIndex,
        nextCell,
        nextIndex,
      });

      if (!nextCell) {
        return;
      }

      if (selectedCellIds.has(nextCell.id)) {
        deselectCells(lastSelectedCellId);
        dispatch(setMostRecentlySelectedCellId(nextCell.id));
      } else {
        selectCells(nextCell.id, { replace: false });
      }
    }, [
      deselectCells,
      dispatch,
      getCellOrders,
      getSelectedCellIds,
      maybeHandleSelectFollowingBlockCell,
      selectCells,
      store,
    ]);

  const addPreviousCellToSelectedRange: UseSelectCellResult["addPreviousCellToSelectedRange"] =
    useCallback(() => {
      const lastSelectedCellId =
        store.getState().logicView.mostRecentlySelectedCellId;
      if (lastSelectedCellId == null) {
        return;
      }
      const selectedCellIds = getSelectedCellIds();
      const cells = getCellOrders();
      const currentIndex = cells.findIndex((c) => c.id === lastSelectedCellId);
      const prevIndex = currentIndex === -1 ? 0 : currentIndex - 1;

      if (prevIndex < 0) {
        return;
      }

      let prevCell: CellMP | undefined = cells[prevIndex];
      prevCell = maybeHandleSelectPreviousBlockCell({
        cells,
        currentIndex,
        prevCell,
        prevIndex,
      });

      if (!prevCell) {
        return;
      }

      if (selectedCellIds.has(prevCell.id)) {
        deselectCells(lastSelectedCellId);
        dispatch(setMostRecentlySelectedCellId(prevCell.id));
      } else {
        selectCells(prevCell.id, { replace: false });
      }
    }, [
      deselectCells,
      dispatch,
      getCellOrders,
      getSelectedCellIds,
      maybeHandleSelectPreviousBlockCell,
      selectCells,
      store,
    ]);

  return {
    selectCells,
    deselectCells,
    selectCellsByStaticId,
    selectCellRange,
    selectFollowingCell,
    selectPreviousCell,
    addNextCellToSelectedRange,
    addPreviousCellToSelectedRange,
    scrollToCell,
  };
}

export function useSelectCellClickHandler({
  cellId,
}: {
  cellId: CellId;
}): React.MouseEventHandler {
  const store = useStore();
  const getSelectedCellIds = useGetSelectedCellIds();
  const { deselectCells, selectCellRange, selectCells } = useSelectCell();

  return useCallback(
    (evt) => {
      const { mostRecentlySelectedCellId } = store.getState().logicView;
      const selectedCellIds = getSelectedCellIds();

      const holdingShiftEvt = evt.shiftKey;
      const holdingModEvt = evtModKey(evt);

      if (holdingShiftEvt && mostRecentlySelectedCellId != null) {
        selectCellRange(mostRecentlySelectedCellId, cellId, {
          replace: false,
        });
      } else if (holdingModEvt) {
        if (selectedCellIds.has(cellId)) {
          deselectCells(cellId);
        } else {
          selectCells(cellId, { replace: false });
        }
      } else {
        selectCells(cellId);
      }
    },
    [
      cellId,
      deselectCells,
      getSelectedCellIds,
      selectCellRange,
      selectCells,
      store,
    ],
  );
}

export function useSelectionIncludesAirlockCells(): boolean {
  const cellsById = useCellsSelector({
    selector: (cellState) => cellState,
  });
  const getSelectedCellIds = useGetSelectedCellIds();
  const airlockCellIds = useAirlockCellIds();
  const airlockBlockCellIds = useAirlockBlockCellIds();

  return Array.from(getSelectedCellIds()).some((id) => {
    const cell = cellsById[id];
    if (cell == null) {
      return false;
    }

    return (
      airlockCellIds.includes(id) ||
      (cell.parentBlockCellId &&
        airlockBlockCellIds.includes(cell.parentBlockCellId))
    );
  });
}
